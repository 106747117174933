import React from 'react';
import './css/style.css';

import ceo from './img/ceo.jpg'
import adage from './img/adage.jpg'
import signature from './img/signature.png'

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";
import {Link} from "react-router-dom";


function Greeting() {
    return (
        <>
            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>会長挨拶｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスをご支援ご愛顧くださるお客様、お取引先様に向けた、当社会長からのメッセージを掲載しております。"/>
            </Helmet>
            <TopBarTitle title="会長挨拶"></TopBarTitle>
            <div id='greeting'>

                <div className='ceo-mobile fadeInTopFlag'>
                    <img src={ceo} className='cssanimation' alt=''/>
                </div>

                <div className='adage-mobile fadeInTopFlag'>
                    <img src={adage} className='cssanimation' alt=''/>
                </div>

                <div className='container'>
                    <div className='box'>
                        <div className='adage2'>
                            <img src={adage} className='cssanimation fadeInTopFlagItem' alt=''/>
                            <div className='message1 cssanimation fadeInFlagItem'>
                                <p>
                                    「日本で一番高い山は」と聞くと誰もが富士山と答えるでしょう。
                                </p>
                                <div>
                                    しかし「二番目に高い山は」と聞くと答えられる人は何人いるのでしょうか。<br/>
                                    同様に「二番目に長い川や二番目に大きい湖は」と聞いても答えられる人は何人もいないでしょう。<br/>
                                    一番は分かっていても二番三番は分からないものです。<br/>
                                    しかし、美しい山川や湖は、と聞くと、答えは千差万別でしょう。<br/>
                                    美しいと感ずるものには一番二番の区別はないのです。<br/>
                                    言い換えれば、美しいもの一つ一つが唯一（ONLY ONE）だからこそ人々の記憶に残るのです。
                                </div>
                            </div>
                        </div>
                        <div className='ceo2 fadeInRightFlag'>
                            <img src={ceo} className='cssanimation' alt=''/>
                        </div>
                        <div className='message2 cssanimation fadeInFlagItem'>
                            <p>
                                弊社が求めていく企業像は、記憶に残る独自の個性（美しさ）を持つ「ONLY ONE企業」です。<br/>
                                そのONLY ONE企業の確立に、四つの慶び「お客様の慶び、地域の方の慶び、社員の慶び、 未来への慶び」を企業理念に掲げ、常に社員全員が慶びの創造に心がけています。
                            </p>
                            <p>
                                慶びの創造を継続するには、個性に溢れ熱く燃えるONLY ONE社員の創造が絶対的に必要です。<br/>
                                弊社の最重要課題として、三慶塾を始め様々なプログラムを設けて人材育成、人材発掘に日々取り組んでいます。
                            </p>
                            <p>
                                私は社員に「尽人事而後楽天」という言葉を常に説いています。<br/>
                                真の楽天的境地というものは、最高の人事を尽くさねば得られない、という事です。<br/>
                                「果報は寝て待て」も最善を尽くしての事です。<br/>
                                最善を尽くせば必ず結果は付いてきます。<br/>
                                結果が付いてこなければ、また最善を尽くすのみです。
                            </p>
                            <p>
                                結びに、最高の社員が、最高のサービスをお客様に提供する事で、思いやりと慶びに溢れた「ONLY ONE企業」になれると信じて、これからも日々社員と共に精進してまいります。
                            </p>
                        </div>
                        <div className='sign fadeInRightFlag cssanimation'>
                            <div><span>代表取締役会長</span></div>
                            <img src={signature} className='cssanimation' alt=''/>
                        </div>

                    </div>
                </div>
                <div id='motto'>
                    <Link to={'/motto'}>
                        <div className='motto'>
                            企業理念・社訓<span className='ku'></span>
                        </div>
                    </Link>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Greeting;
