import React, {useEffect} from 'react';
import {RecoilRoot} from "recoil";
import {Route, useLocation} from "react-router-dom";
import './App.css';
import './header/header'
import 'bootstrap/dist/css/bootstrap.min.css'
import './common/css/cssanimation.css'
import './common/css/style.scss'
import './common/css/bootstrap-print.css'
import './common/css/print.scss'
// @ts-ignore
import SlideRoutes from "react-slide-routes";
import Top from './pages/top/top'
import Business from "./pages/business/business";
import Header from "./header/header";
import Company from "./pages/company/company";
import Group from "./pages/group/group";
import Profile from "./pages/profile/profile";
import Greeting from "./pages/greeting/greeting";
import Privacy from "./pages/privacy/privacy";
import Sitemap from "./pages/sitemap/sitemap";
import Contact from "./pages/contact/contact";
import Motto from "./pages/motto/motto";
import NotFound from "./pages/404/notFound";

function App() {
    const location = useLocation();

    useEffect(() => {
        window.setTimeout(() => {
            // @ts-ignore
            window.gtag('event', 'page_view_original', {
                'event_category': 'pageView',
                'event_label': location.pathname,
                'value': '0'
            });
            // @ts-ignore
            window.gtag('event', 'page_view');
        });
    }, [location]);

    useEffect(() => {
        let animateElementsFadeInTop = document.getElementsByClassName('fadeInTopFlag');
        let animateElementsFadeInLeft = document.getElementsByClassName('fadeInLeftFlag');
        let animateElementsFadeInRight = document.getElementsByClassName('fadeInRightFlag');
        let animateElementsFadeInRightNoInitial = document.getElementsByClassName('fadeInRightFlagContainer');
        let animateElementsFadeIn = document.getElementsByClassName('fadeInFlag');
        let animateElementsFadeInItem = document.getElementsByClassName('fadeInFlagItem');
        let animateElementsFadeInTopItem = document.getElementsByClassName('fadeInTopFlagItem');
        window.addEventListener('scroll', function () {
            let scrollValue = window.pageYOffset;
            let windowHeight = window.innerHeight;
            for (let a = 0; a < animateElementsFadeInTop.length; a++) {
                let scrollTop = animateElementsFadeInTop[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInTop[a].classList.add('leFadeInTop');
                }
            }
            for (let a = 0; a < animateElementsFadeInLeft.length; a++) {
                let scrollTop = animateElementsFadeInLeft[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInLeft[a].classList.add('leFadeInLeft');
                }
            }
            for (let a = 0; a < animateElementsFadeInRight.length; a++) {
                let scrollTop = animateElementsFadeInRight[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInRight[a].classList.add('leFadeInRight');
                }
            }
            for (let a = 0; a < animateElementsFadeInRightNoInitial.length; a++) {
                let scrollTop = animateElementsFadeInRightNoInitial[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInRightNoInitial[a].classList.add('fadeInRight');
                }
            }
            for (let a = 0; a < animateElementsFadeIn.length; a++) {
                let scrollTop = animateElementsFadeIn[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeIn[a].classList.add('leFadeIn');
                }
            }
            for (let a = 0; a < animateElementsFadeInItem.length; a++) {
                let scrollTop = animateElementsFadeInItem[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInItem[a].classList.add('fadeIn');
                }
            }
            for (let a = 0; a < animateElementsFadeInTopItem.length; a++) {
                let scrollTop = animateElementsFadeInTopItem[a].getBoundingClientRect().top + scrollValue;
                if (scrollValue + 100 > scrollTop - windowHeight) {
                    animateElementsFadeInTopItem[a].classList.add('fadeInTop');
                }
            }
        });
    }, []);
    return (
        <>
            <RecoilRoot>
                <Header></Header>
                <SlideRoutes>
                    <Route path={'/'} element={<Top></Top>}/>
                    <Route path={'/business'} element={<Business></Business>}/>
                    <Route path={'/company'} element={<Company></Company>}/>
                    <Route path={'/group'} element={<Group></Group>}/>
                    <Route path={'/profile'} element={<Profile></Profile>}/>
                    <Route path={'/greeting'} element={<Greeting></Greeting>}/>
                    <Route path={'/privacy'} element={<Privacy></Privacy>}/>
                    <Route path={'/sitemap'} element={<Sitemap></Sitemap>}/>
                    <Route path={'/contact'} element={<Contact></Contact>}/>
                    <Route path={'/motto'} element={<Motto></Motto>}/>
                    <Route path={'/*'} element={<NotFound></NotFound>}/>
                </SlideRoutes>
            </RecoilRoot>
        </>
    );
}

export default App;
