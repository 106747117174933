import React from 'react';
import './css/style.css';

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";


function Privacy() {
    return (
        <>
            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>プライバシーポリシー｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスでは、事業を進めるにあたり、当｢個人情報保護方針｣を遵守し徹底するよう努めております。"/>
            </Helmet>
            <TopBarTitle title="プライバシーポリシー"></TopBarTitle>
            <div id='privacy'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12">
                            <div className="box">
                                <div className="row">
                                    <div className="col-lg-10 col-lg-push-1">
                                        <h2>FDSホールディングス プライバシーポリシー</h2>
                                        <br/>
                                        <h3>はじめに</h3>
                                        <p>このWebサイト（以下「本サイト」といいます）は、株式会社FDSホールディングス（以下「当社」といいます）の企業・グループ情報・新しい動きなどを紹介しています。
                                            本サイトのご利用にあたっては、以下のプライバシー・ポリシー（以下「本規約」といいます）をよくお読みいただき、ご同意の上、ご利用いただくようお願い申し上げます。ご同意いただけない場合には、申し訳ございませんがご利用をお控えください。また、ご利用いただいた場合には、本規約すべてにご同意いただいたものとさせていただきますのでご了承願います。</p>
                                        <br/>
                                        <h3>1.サイトのご利用に関する免責事項</h3>
                                        <p>当サイトは、皆さまに当社の会社概要、方針等の情報をご理解いただくことを目的としており、当社株式の購入、売却等の投資を勧誘するものではありません。
                                            当サイトに掲載されている情報は、当社が入手可能な情報の正確性や完全性に依拠し、前提としていますが、その正確性あるいは完全性について、当社は何ら表明及び保証するものではありません。また、掲載されている情報は事前に通知することなく変更されることがあります。
                                            将来の展望に関して掲載された内容は、将来予想に関する記述です。将来予想に関する記述には、これに限りませんが「信じる」、「予期する」、「計画」、「戦略」、「期待する」、「予想する」、「予測する」又は、「可能性」や将来の事業活動、業績、出来事や状況を説明するその他類似した表現を含みます。将来予想に関する記述は、現在入手可能な情報をもとにした当社の経営陣の判断に基づいています。そのため、これらの将来に関する記述は、様々なリスクや不確定要素に左右され、実際の業績は将来に関する記述に明示又は黙示された予想とは大幅に異なる場合があります。従って、将来予想に関する記述に全面的に依拠することのないようご注意ください。新たな情報、将来の出来事やその他の発見に照らして、将来予想に関する記述を変更又は訂正する一切の義務を当社は負いません。</p>
                                        <br/>
                                        <h3>2. ご注意</h3>
                                        <h4>2-1. 内容の不保証</h4>
                                        <p>当社は、本サイトに細心の注意を払って、情報を掲載しています。しかしながら、サイト上の情報については、当社は明示であれ黙示であれ、本サイトの内容の正しさ、正確さ、信頼性等につき、なんら保証しません。また、当社は、本サイトの機能が中断しないこと、もしくはエラーが発生しないこと、本サイトおよびサーバーにコンピューターウィルスその他の有害なものが含まれていないこと等についても、一切保証しません。</p>
                                        <h4>2-2. ご利用上のトラブル等</h4>
                                        <p>本サイトの内容に誤りがあった場合、または本サイトのご利用に際してご利用者様と第三者との間のトラブルが生じた場合、当社は一切責任を負いかねます。また、本サイトのご利用に起因するソフトウェア、ハードウェア上の事故その他の損害についても、必要なサービス、修理等についてのあらゆる費用は、すべてご利用者様のご負担となります。当社は、当社の責に帰すべき事由のある場合を除き、一切責任を負いかねます。</p>
                                        <h4>2-3. 内容の変更等</h4>
                                        <p>本サイトに掲載されている情報、ファイル名等は、予告なく変更、修正、削除等されることがありますので、あらかじめご了承ください。</p>
                                        <h4>2-4. 責任の制限</h4>
                                        <p>本サイトのご利用に際して、ご利用者様が蒙った損害について当社が責任を負う場合であっても、故意または重過失がない限り当社の責任は直接かつ通常の損害に限られるものとします。</p>
                                        <br/>
                                        <h3>3. 不可抗力</h3>
                                        <p>当社は、通常講ずるべきウィルス対策では防止できないウィルス、天変地異、その他当社の責によらない事由により被害が生じた場合には、一切責任を負わないものとします。
                                        </p>
                                        <br/>
                                        <h3>4. 個人情報の取り扱いについて</h3>
                                        <p>当社は、個人情報をそれぞれ以下の目的で利用いたします。</p>
                                        <h4>4-1.個人情報（保有個人データを含む）の利用目的</h4>
                                        <p>当社は、次に掲げる利用目的により、個人情報及び保有個人データを利用いたします。</p>
                                        <p>・お取引様及びユーザの皆様の個人情報</p>
                                        <p> お取引先様との間のご契約に基づく権利の行使・義務の履行のため<br/> ユーザの皆様に対するサービス提供のため<br/> 当社製品・サービスに関するご案内・サポートを行うため
                                        </p>
                                        <p>・当社へお問合せ頂いた方の個人情報</p>
                                        <p> お問い合わせやご連絡内容を正確に把握し、対処するため<br/> 当社の接客態度等の向上のため</p>
                                        <p>・お取引先様から委託を受ける業務において取り扱う個人情報</p>
                                        <p> 当該業務を行うため</p>
                                        <p>・デバイスから得られたデータ</p>
                                        <p> システム・アプリケーション開発のため</p>
                                        <p> システム・アプリケーションの機能向上のため</p>

                                        <p>上記以外の目的で当該個人情報を利用させていただく場合は、その都度、その利用目的を明確にし、ご本人から事前の同意をいただきます。ただし、次の場合は除きます。</p>
                                        <p>1.利用目的を本人に通知し、又は公表することにより本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p>
                                        <p>2.利用目的を本人に通知し、又は公表することにより当社の権利又は正当な利益を害するおそれがある場合</p>
                                        <p>3.国の機関又は地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的を本人に通知し、又は公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
                                        <p>4.取得の状況からみて利用目的が明らかであると認められる場合</p>
                                        <h4>4-2.第三者提供</h4>
                                        <p>当社は、取り扱う個人情報について、以下のいずれかに該当する場合を除き、いかなる第三者にも開示いたしません。</p>
                                        <p>1.ご本人の同意がある場合</p>
                                        <p>2.裁判所・法令等に基づき公官庁により要求された場合</p>
                                        <p>3.当社の権利や財産を保護する目的による場合</p>
                                        <p>4.その他法令により許される場合</p>

                                        <h4>4-3.個人情報に関するお問い合わせ窓口</h4>
                                        <p>当社の個人情報の取り扱いに関するお問い合わせは、以下連絡先までご連絡ください。</p>
                                        <p>株式会社FDSホールディングス<br/>住所：〒060-0004 北海道札幌市中央区北4条西13丁目1-2RICH植物園BLDG.3F<br/>電話：011-616-0808<br/>電子メール：developer@fds-hd.co.jp<br/>（受付時間：午前9時～午後5時、土日祝祭日、年末年始、当社休業日を除く）
                                        </p>

                                        <h4>4-4.保有個人データの安全管理</h4>
                                        <p>個人情報の取扱いと個人情報保護マネジメントシステムについて、適切な運用が実施されるよう管理と必要な是正を行うとともに、社会情勢・環境等の変化を考慮して個人情報保護の取組みを継続的に見直し、改善していきます。</p>
                                        <br/>
                                        <h4>個人情報管理責任者</h4>
                                        <p>株式会社FDSホールディングス 個人情報管理責任者</p>
                                        <br/>
                                        <h3>サイトのご利用に関して</h3>
                                        <br/>
                                        <h3>1.Cookie（クッキー）について</h3>
                                        <p>当社は、ご利用者様にとって便利な機能を提供したり、閲覧状況の統計的な情報収集を行うことによって、より使いやすいウェブサイトを実現することを目的に、一部のページにおきまして、Cookieという技術を利用しています。<br/>
                                            Cookieに保存された情報を、ご利用者様のご希望により、ご利用者様の入力補助のために使用することはございますが、Cookieを前述以外の目的で個人情報と関連付けて利用することはありません。<br/>
                                            なお、Cookieに保存されている情報のみでは、ご利用者様個人を特定することはできません。<br/>
                                            ご利用者様ご自身でブラウザの設定を変更することにより、Cookieの受け取りを拒否したり、受け取ったときに警告を表示させることができますが、拒否された場合には、当社本サイトの一部サービスがご利用できなくなることがありますのであらかじめご了承ください。
                                        </p>
                                        <br/>
                                        <h3>2. 著作権について</h3>
                                        <p>本サイトで提供されているコンテンツ（文章、画像、映像、音声、プログラムなど）の著作権は、当社または当社にその利用を認めた権利者に帰属しています。ご利用者様個人で利用するのみの場合を除き、他のWebサイトや印刷媒体、その他の記録媒体等において、複製、改変、翻案などをすることはできません。その他著作権法で認められている範囲を超えて、本サイトで提供されているコンテンツを当社の事前の書面による承諾なく使用することはできません。</p>
                                        <br/>
                                        <h3>3. 商標について</h3>
                                        <p>本サイトに表示される社名、商品やサービス等の名称・ロゴ等に関する権利は、当社または個々の権利の所有者に帰属します。商標法、その他の法律で認められる場合を除き、当社または個々の権利の所有者の事前の書面による承諾なくこれらを使用することはできません。なお、以下は、個々の権利の所有者に帰属する権利の一例となりますが、これに限られません。</p>
                                        <br/>
                                        <h3>4. リンクについて</h3>
                                        <p>リンクの際のURLは「https://www.fds-hd.co.jp/」を基本とし、かつ株式会社FDSホールディングスのWebサイトである旨を明示してください。なお、当社は当該URLを変更し、またはその運営を変更、停止または中止等することがありますので、あらかじめご了承ください。また、当社は、リンク元サイトの内容やリンクの方法が、本サイト運営の意図に反すると当判断した場合、リンクを解消していただくことがありますので、併せてご了承ください。</p>
                                        <br/>
                                        <h3>その他</h3>
                                        <br/>
                                        <h3>１. 本規約について</h3>
                                        <h4>1-1. 有効性</h4>
                                        <p>本規約のうちの一部が法律違反、無効、または何らかの理由で有効でないとみなされた場合でも、本規約のその他の部分は継続して完全に効力を有するものとします。</p>
                                        <h4>1-2. 変更等</h4>
                                        <p>当社は本規約を当社の都合により、変更、追加、削除等することがありますので、常に最新の条件をご確認ください。変更後に本サイトをご利用された場合、その変更に同意されたものとみなします。</p>
                                        <h4>1-3. リンク先サイト</h4>
                                        <p>本サイトにリンクが掲載されたWebサイトのご利用に際しては、運営者が当社であるか否かに関わらず、当該サイトごとに定められている規約等に従うことになりますので、ご注意ください。</p>
                                        <br/>
                                        <h3>2. 紛争等</h3>
                                        <h4>2-1. 準拠法、裁判管轄</h4>
                                        <p>本サイトのご利用並びに本規約の解釈及び適用は、日本国法に準拠するものとします。</p>
                                        <h4>2-2. 当社の対応</h4>
                                        <p>ご利用者様が、本規約や当社の本サイト運営の趣旨に反して本サイトをご利用された場合等において、当社は、必要な法的措置と併せて、利用停止その他の当社が必要と認める対処をすることができます。</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Privacy;
