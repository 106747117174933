import React from 'react';
import './css/style.css';

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";
import {Link} from "react-router-dom";


function Sitemap() {
    return (
        <>
            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>サイトマップ｜FDS</title>
                <meta name="Description"
                      content="株式会社FDSホールディングスのサイトマップです。"/>
            </Helmet>
            <TopBarTitle title="サイトマップ"></TopBarTitle>
            <div id='sitemap'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12">
                            <div className="sitemapbox">
                                <div className="row">
                                    <div className="col-lg-12 col-lg-push-1">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                            <tr>
                                                <td><Link to={'/'}>トップページ</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/business'}>事業内容</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/company'}>会社案内</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/group'}>グループ会社</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/profile'}>会社概要</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/greeting'}>会長挨拶</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/motto'}>企業理念・社訓</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/privacy'}>プライバシーポリシー</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/sitemap'}>サイトマップ</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/contact'}>お問い合わせ</Link></td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Sitemap;
