import React, {useState} from 'react';
import './css/style.css';

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";
import {useForm} from 'react-hook-form';
import axios from "axios";
import Reaptcha from "reaptcha";


import Success from "./success";
import {useRecoilState} from "recoil";
import {contactContentSelectedInBusiness} from "../../globalState/contactContentSelectedInBusiness";



const siteKey = "6LdgjqchAAAAAG3CTeiPX9vXYwwZjoR7gxF5D1Zl";


interface IFormInputs {
    categoryPC: string;
    categorySP: string;
    corporation: string;
    name: string;
    kana: string;
    mail: string;
    phone: string;
    content: string;
    token: string;
}

function Contact() {
    const [isCheck, setIsCheck] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [, setToken] = useState('');
    const [inquiryState,] = useRecoilState(contactContentSelectedInBusiness)
    const {register, handleSubmit, formState: {errors}, setValue,} = useForm<IFormInputs>({
        defaultValues: {
            categoryPC: inquiryState,
            categorySP: inquiryState,
        },
    });


    const [isSuccessContact, setIsSuccessContact] = useState(false);

    function phoneChange(event: React.ChangeEvent<HTMLInputElement>): void {
        let str = event.target.value;
        if (!str.match(/^[0-9０-９ー-]+$/)) {
            str = str.substring(0, str.length - 1);
        }
        event.target.value = str;
    }

    function phoneAdjust(phone: string): string {
        let str = phone;
        str = str.replace(/[０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
        str = str.replace(/[ー-]/g, '');
        return str;
    }

    const onSubmit = async (data: IFormInputs) => {
        setIsLoading(true);

        //クリックイベント検証用〜
        // let res;
        // console.log("クリックしました。");
        // setInterval(function (){
        //     res = true;
        //     if(res){
        //         setIsSuccessContact(true);
        //     } else {
        //         alert("メールの送信に失敗しました。メールアドレスをご確認のうえ、再度送信してください。")
        //         window.grecaptcha?.reset();
        //         setIsLoading(false);
        //     }
        // },3000);


        const res = await axios.get("https://corporateapi.fds-hd.co.jp/fdshp/send_mail", {
            params: {
                category: window.innerWidth <= 800 ? data.categorySP : data.categoryPC,
                corporation: data.corporation,
                name: data.name,
                kana: data.kana,
                email: data.mail,
                phone: phoneAdjust(data.phone),
                content: data.content,
                token: data.token,
            }
        });

        if (res.data['result']) {
            // @ts-ignore
            window.gtag('event', 'Click', {'event_category': 'contact', 'event_label': "send_Success", 'value': '0'});
            setIsSuccessContact(true);
        } else {
            // @ts-ignore
            window.gtag('event', 'Click', {'event_category': 'contact', 'event_label': "send_Failure", 'value': '0'});
            alert("メールの送信に失敗しました。メールアドレスをご確認のうえ、再度送信してください。")
            window.grecaptcha?.reset();
            setIsLoading(false);
        }
    }

    function onCheck(value: string | null): void {
        setToken(value ?? '');
        setIsCheck(true);
        setValue('token', value ?? '');
    }

    function onExpire(): void {
        setIsCheck(false);
    }

    return (
        <>

            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>お問い合わせ｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスへの各種お問い合わせフォームをご用意しております。ご不明点がございましたらこちらのページからお問い合わせください。"/>
            </Helmet>
            <TopBarTitle title="お問い合わせ"></TopBarTitle>
            <div id='contact' className='container'>
                {isSuccessContact ? <Success></Success> :
                    <form onSubmit={handleSubmit(onSubmit)} className='col-12' id='contactForm' noValidate={true}>

                        <div className='col-12 row'>
                            <label className='col-3'>お問い合わせ内容</label>
                            <div className='col-9 row radio-item-box pc'>
                                <div className='col-6'>
                                    <div className='radio-item'>
                                        <input {...register("categoryPC", {required: true})} type="radio"
                                               value="ITシステムAPP開発について" id='radio2'/>
                                        <label htmlFor="radio2">ITシステムAPP開発について</label>
                                    </div>
                                    <div className='radio-item'>
                                        <input {...register("categoryPC", {required: true})} type="radio" value="その他"
                                               id='radio3'/>
                                        <label htmlFor="radio3">その他</label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='radio-item'>
                                        <input {...register("categoryPC", {required: true})} type="radio"
                                               value="損害保険について" id='radio4'/>
                                        <label htmlFor="radio4">損害保険について</label>
                                    </div>
                                    <div className='radio-item'>
                                        <input {...register("categoryPC", {required: true})} type="radio"
                                               value="コンサルティングについて" id='radio5'/>
                                        <label htmlFor="radio5">コンサルティングについて</label>
                                    </div>


                                </div>

                            </div>

                            <select className='dropdown-item-box tab col-9 form-select' {...register("categorySP", {})}>
                                <option value="損害保険について">損害保険について</option>
                                <option value="ITシステムAPP開発について">ITシステムAPP開発について</option>
                                <option value="コンサルティングについて">コンサルティングについて</option>
                                <option value="その他">その他</option>
                            </select>
                        </div>

                        <div>
                            <label className='col-3'>御社名</label>
                            <input className='col-9' type="text" placeholder="" {...register("corporation", {
                                maxLength: {
                                    value: 200,
                                    message: `200文字以内で入力してください。`
                                }
                            })} />
                            {errors.corporation && <div className='error'>{errors.corporation.message}</div>}
                        </div>
                        <div>
                            <label className='col-3'>お名前<span className="mustIcon">必須</span></label>
                            <input className='col-9' type="text" placeholder="" {...register("name", {
                                required: 'お名前を入力してください',
                                maxLength: {value: 200, message: `200文字以内で入力してください。`}
                            })} />
                            {errors.name && <div className='error'>{errors.name.message}</div>}
                        </div>
                        <div>
                            <label className='col-3'>フリガナ</label>
                            <input className='col-9' type="text" placeholder="" {...register("kana", {
                                maxLength: {
                                    value: 200,
                                    message: `200文字以内で入力してください。`
                                }
                            })} />
                            {errors.kana && <div className='error'>{errors.kana.message}</div>}
                        </div>
                        <div>
                            <label className='col-3'>メールアドレス<span className='kome tab'>※返答がご希望の方は入力してください</span></label>
                            <span className='col-9 position-relative'>
                                <div className='pc'>※返答がご希望の方は入力してください</div>
                                <input className='col-9' type="email" placeholder="" {...register("mail", {
                                    pattern: {
                                        value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                                        message: "入力形式がメールアドレスではありません。"
                                    }, maxLength: {value: 200, message: `200文字以内で入力してください。`}
                                })} />{errors.mail && <div className='error'>{errors.mail.message}</div>}
                            </span>
                        </div>
                        <div>
                            <label className='col-3'>電話番号<span className='kome tab'>※返答がご希望の方は入力してください</span></label>
                            <span className='col-9 position-relative'>
                                <div className='pc'>※返答がご希望の方は入力してください</div>
                                <input className='col-9' type="tel" placeholder="" {...register("phone", {
                                    maxLength: {
                                        value: 20,
                                        message: `20文字以内で入力してください。`
                                    }, pattern: {value: /[0-9０-９ー-]+/i, message: '数字で入力してください。'}
                                })} onChange={(event) => phoneChange(event)}/>
                            </span>
                            {errors.phone && <div className='error'>{errors.phone.message}</div>}
                        </div>
                        <div className="flex">
                            <label className='col-3'>具体的な内容<span className="mustIcon">必須</span></label>
                            <textarea className='col-9' {...register("content", {
                                required: '内容を入力してください',
                                maxLength: {value: 2000, message: `2000文字以内で入力してください。`}
                            })} />
                            {errors.content && <div className='error'>{errors.content.message}</div>}
                        </div>

                        <div className='privacy'>
                            <div>
                                以下のプライバシーポリシーをご確認の上、ご了承いただいた方はボタンを押して送信ください。
                            </div>
                            <a target="_blank" href='/privacy'>
                                プライバシーポリシーはこちら
                            </a>
                        </div>

                        <Reaptcha className='reCaptcha' sitekey={siteKey} onVerify={onCheck} onExpire={onExpire}/>

                        <input type="hidden" {...register("token")} />

                        <div className='submit-container'>
                            <input className='submit' type="submit" value='上記の内容で送信　➣'
                                   disabled={!(isCheck && !isLoading)}/>
                        </div>
                    </form>
                }
            </div>
            <Footer></Footer>
        </>
    );
}


export default Contact;
