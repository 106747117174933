import React from 'react';
import './css/style.css';

import motto from './img/motto.jpg'

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";


function Motto() {
    return (
        <>
            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>企業理念・社訓｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスグループの全従業員は、本ページ掲載の社訓を軸に、お客様や社会に必要とされる存在となるよう日々行動しております。"/>
            </Helmet>
            <TopBarTitle title="企業理念・社訓"></TopBarTitle>
            <div id='motto'>
                <div className='container max1000'>
                    <div className='col-5 image fadeInRightFlag'>
                        <img src={motto} className='cssanimation' alt=''/>
                    </div>
                    <div className='col-7 container2'>
                        <div className='rinen'>
                            <div className='title fadeInLeftFlag cssanimation'>
                                <span>企業理念</span>
                            </div>
                            <div className='description fadeInLeftFlag cssanimation'>
                                <span>私たちのグループは</span><br/>
                                <span>お客様の慶び、地域の方の慶び、<br className='tab'/> 社員の慶び、未来への慶び</span><br/>
                                <span>四つの慶びを創造する企業グループです</span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>一、お客様の慶びとは、</div>
                                    <div>安心・信頼・信用を提供する企業</div>
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>一、地域の方の慶びとは、</div>
                                    <div>地域に貢献する企業</div>
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>一、社員の慶びとは、</div>
                                    <div>お客様と喜びを共有する自己実現の場</div>
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>一、未来への慶びとは、</div>
                                    <div>家族と夢を創造する企業</div>
                                </span>
                            </div>
                        </div>
                        <div className='motto'>
                            <div className='title fadeInLeftFlag cssanimation'>
                                <span>社訓</span>
                            </div>
                            <div className='description'>
                                <span>
                                    企業理念の実践<br/>
                                    オンリーワンを目指すために
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>【スピード】</div>
                                    <div>「明日」より、「今」より、<br className='tab'/> 「既に」、を心がける</div>
                                    <div>
                                        先を見通し、誰に言われずとも先に進め<br/>
                                        いつ死ぬかわからないこそ、明日はないと思え<br/>
                                        目の前のことを本気で楽しみ、本気で努力せよ
                                    </div>
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>【創造性】</div>
                                    <div>常識ではなく非常識に挑戦する</div>
                                    <div>
                                        チャレンジすることによる失敗は会社の財産である<br/>
                                        失敗を恐れず、自分を磨く糧とせよ<br/>
                                        失敗を活かし、人として輝け
                                    </div>
                                </span>
                            </div>
                            <div className='sentence fadeInLeftFlag cssanimation'>
                                <span>
                                    <div>【人間性】</div>
                                    <div>
                                        常に感謝と思いやりの気持ちを持ち、<br/>
                                        信頼と協調を築き上げる
                                    </div>
                                    <div>
                                        人の痛みを感じとり、たくさんの人を愛し、<br/>
                                        そして愛される人となれ<br/>
                                        自分の目で本物と偽物を見抜ける人となれ
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Motto;
