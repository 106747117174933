import React, {useState} from 'react';
import logo from './logo.png';
import './style.css';
import './hamburger.css'
import {Link, useLocation} from "react-router-dom";


function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const switchIsOpen = () => {
        setIsOpen(!isOpen);
    }
    const [isOpenHeadline, setIstOpenHeadline] = useState([false, false, false, false]);
    const switchIsOpenHeadline = (index: number) => {
        setIstOpenHeadline(isOpenHeadline.map((b, i) => (i === index ? !b : b)));
    }

    function analyticsHeader(link: string) {
        // @ts-ignore
        window.gtag('event', 'Click', {'event_category': 'header', 'event_label': link, 'value': '0'});
    }

    return (
        <div className='sticky-top'>
            <div id="header">
                {
                    useLocation().pathname==="/" ?
                        <h1 id="h1Title"><Link to='/' onClick={() => {
                            setIsOpen(false);
                            analyticsHeader('logo')
                        }}>株式会社FDSホールディングス｜中小企業と未来を歩く</Link></h1> :
                        <Link to='/' onClick={() => {
                            setIsOpen(false);
                            analyticsHeader('logo')
                        }}><img src={logo} alt='logo'></img></Link>
                }
                {/*ハンバーガー*/}
                <div id='hamburger' onClick={switchIsOpen} className={isOpen ? 'active' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div id='insideContainer'>
                    <div id='hamburgerInside' className={isOpen ? 'active' : ''}>
                        {
                            useLocation().pathname==="/business" ?
                                <div className='headline'>事業内容<span className='ku'></span></div>:
                                <Link to='/business' className='headline' onClick={() => {
                                    switchIsOpen();
                                    analyticsHeader('事業内容')
                                }}>
                                    事業内容<span className='ku'></span>
                                </Link>
                        }
                        <a target="_blank" rel="noreferrer" href='https://fds-hd-recruit.com/' className='headline' onClick={() => {
                            switchIsOpen();
                            analyticsHeader('採用情報')
                        }}>
                            採用情報<span className='ku'></span>
                        </a>
                        {
                            useLocation().pathname==="/company" ?
                                <div className='headline' onClick={() => switchIsOpenHeadline(2)}>
                                    会社案内<span className={isOpenHeadline[2] ? 'plus active' : 'plus'}></span>
                                </div>:
                                <div className='headline' onClick={() => switchIsOpenHeadline(2)}>
                                    <Link to={"/company"} onClick={()=>{
                                        switchIsOpen();
                                        analyticsHeader('会社案内')
                                    }}>会社案内</Link><span className={isOpenHeadline[2] ? 'plus active' : 'plus'}></span>
                                </div>
                        }
                        <div className={isOpenHeadline[2] ? 'submenu open2' : 'submenu'}>
                            {
                                useLocation().pathname==="/profile" ?
                                    <div className='headline'>会社概要<span>＞</span></div>:
                                    <Link to='/profile' onClick={() => {
                                        switchIsOpen();
                                        analyticsHeader('会社概要')
                                    }}>
                                        <div>会社概要<span>＞</span></div>
                                    </Link>
                            }
                            {
                                useLocation().pathname==="/group" ?
                                    <div className='headline'>グループ会社<span>＞</span></div>:
                                    <Link to='/group' onClick={() => {
                                        switchIsOpen();
                                        analyticsHeader('グループ会社')
                                    }}>
                                        <div>グループ会社<span>＞</span></div>
                                    </Link>
                            }
                            {
                                useLocation().pathname==="/greeting" ?
                                    <div className='headline'>会長挨拶<span>＞</span></div>:
                                    <Link to='/greeting' onClick={() => {
                                        switchIsOpen();
                                        analyticsHeader('会長挨拶')
                                    }}>
                                        <div>会長挨拶<span>＞</span></div>
                                    </Link>
                            }
                        </div>
                        {
                            useLocation().pathname==="/contact" ?
                                <div className='headline'>お問い合わせ<span className='ku'></span></div>:
                                <Link to='/contact' className='headline' onClick={() => {
                                    switchIsOpen();
                                    analyticsHeader('お問い合わせ')
                                }}>
                                    お問い合わせ<span className='ku'></span>
                                </Link>
                        }

                        <div className='bottomItem'>
                            {
                                useLocation().pathname==="/sitemap" ?
                                    <div>サイトマップ</div>:
                                    <Link to='/sitemap' onClick={() => {
                                        switchIsOpen();
                                        analyticsHeader('サイトマップ')
                                    }}>
                                        サイトマップ
                                    </Link>
                            }
                            {
                                useLocation().pathname==="/privacy" ?
                                    <div>プライバシーポリシー</div>:
                                    <Link to='/privacy' onClick={() => {
                                        switchIsOpen();
                                        analyticsHeader('プライバシーポリシー')
                                    }}>
                                        プライバシーポリシー
                                    </Link>
                            }

                        </div>
                    </div>
                </div>
                {/*ハンバーガー*/}
                <div className='menu'>
                    {
                        useLocation().pathname==="/" ?
                            <div>TOP</div>:
                            <Link to='/' onClick={() => analyticsHeader('TOP')}>
                                <div>
                                    TOP
                                </div>
                            </Link>
                    }
                    <span className='no-touch'>
                        &emsp;
                    </span>
                    {
                        useLocation().pathname==="/business" ?
                            <div>事業内容</div>:
                            <Link to='/business' onClick={() => analyticsHeader('事業内容')}>
                                <div>
                                    事業内容
                                </div>
                            </Link>
                    }
                    <span className='no-touch'>
                        &emsp;
                    </span>
                    <a target="_blank" rel="noreferrer" href='https://fds-hd-recruit.com/' onClick={() => analyticsHeader('採用情報')}>
                        <div>
                            採用情報
                        </div>
                    </a>
                    <span className='no-touch'>
                        &emsp;
                    </span>
                    {
                        useLocation().pathname==="/company" ?
                            <div>会社案内</div>:
                            <Link to='/company' onClick={() => analyticsHeader('会社案内')}>
                                <div>
                                    会社案内
                                </div>
                            </Link>
                    }
                    <span className='no-touch'>
                        &emsp;
                    </span>
                    {
                        useLocation().pathname==="/contact" ?
                            <div>お問い合わせ</div>:
                            <Link to={'/contact'} onClick={() => analyticsHeader('お問い合わせ')}>
                                <div>
                                    お問い合わせ
                                </div>
                            </Link>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;
