import React from 'react';
import './css/style.css';
import umeya from './img/web_Umeya_Photo01.png';
import life from './img/web_LifeBuddy_Photo01.png';
import umeya_logo from './img/logo_Umeya.png';
import life_logo from './img/logo_LifeBuddy.png';
import sankey_logo from './img/logo_Sankey.png';
import sankey_base from './img/web_Sankey_Base.jpg';
import sankey_copy from './img/web_Sankey_Copy.png';
import sankey_kita5 from './img/web_Sankey_Kita5jo.png';
import sankey_kita5_add from './img/web_Sankey_Kita5jo_Add.png';
import sankey_otaru from './img/web_Sankey_Otaru.png';
import sankey_otaru_add from './img/web_Sankey_Otaru_Add.png';
import { Helmet } from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";


function Group() {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <meta charSet="utf-8" />
                <title>グループ会社｜FDS</title>
                <meta name="Description"
                    content="FDSホールディングスグループは、菓子処「梅屋」、デイサービスの「らいふばでぃ」、アミューズメント「三慶」を運営しています。" />
            </Helmet>
            <TopBarTitle title="グループ会社"></TopBarTitle>

            <div id='group' className='container'>
                <div className='company fadeInFlag '>
                    <div className='name cssanimation'>
                        株式会社 梅屋
                    </div>
                    <div className='title cssanimation fadeInTopFlag tab'>
                        <div>梅屋は、北海道・旭川のお菓子です。</div>
                    </div>
                    <div className='body'>
                        <div className='fadeInLeftFlag'>
                            <img className='cssanimation' src={umeya} alt='' />
                        </div>

                        <div className='text cssanimation fadeInTopFlagItem '>
                            <div className='pc'>
                                梅屋は、北海道・旭川のお菓子です。
                            </div>
                            <br className='pc' />
                            菓子処「梅屋」は、大正３（１９１４）<br className='tab' />
                            年に旭川で創業しました。
                            <br />
                            旭川の街が生まれて１１０余年ですので、<br className='tab' />
                            今から１００年以上前に和菓子屋<br className='tab' />
                            としてはじまった梅屋は老舗のお菓子屋と<br className='tab' />
                            いえます。
                            <br />
                            <br />
                            旭川は北海道のほぼ中央に位置し、背後に<br className='tab' />
                            雄大な大雪山連邦を望み、清流石狩川を<br className='tab' />
                            はじめとする幾多の川が大地を潤す、<br className='tab' />
                            きわめて自然に恵まれた街です。
                            <br />
                            また、<br className='tab' />
                            北海道では札幌に次ぐ人口（約３４万人）<br className='tab' />
                            を有し、充実する都市機能と自然が美しく<br className='tab' />
                            調和する北・北海道の中心都市として<br className='tab' />
                            発展しつづけています。
                        </div>
                    </div>
                    <div className='button'>
                        <a target="_blank" href="https://www.ho-umeya.co.jp/" rel="noreferrer">
                            <div className='out-frame'></div>
                            <div className='in-frame'></div>
                            <img src={umeya_logo} alt='' />
                            <div className='ku'>{'>'}</div>
                        </a>
                    </div>
                </div>

                <div className='company fadeInFlag'>
                    <div className='name cssanimation'>
                        株式会社 らいふばでぃ
                    </div>

                    <div className='body'>
                        <div className='fadeInRightFlag'>
                            <img className='cssanimation' src={life} alt='' />
                        </div>
                        <div className='text cssanimation fadeInTopFlagItem '>
                            <span>
                                「楽しくリハビリ」「楽しく運動」を<br className='tab' />
                                テーマに明日もまた来ようと思える心<br className='tab' />
                                も体も前向きになれるデイサービス<br />
                            </span>
                            <br />
                            <span>
                                らいふばでぃはスポーツクラブのよう<br className='tab' />
                                なデイサービスを目指しています。<br className='tab' />
                                「元気なスタッフ」「豊富な運動メニ<br className='tab' />
                                ュー」「おもてなしの心」により、ま<br className='tab' />
                                た明日も来たい!!と思えるサービスをご<br className='tab' />
                                提供いたします。
                            </span>
                        </div>
                    </div>

                    <div className='button'>
                        <a target="_blank" href="http://www.life-buddy.co.jp/" rel="noreferrer">
                            <div className='out-frame'></div>
                            <div className='in-frame'></div>
                            <img src={life_logo} alt='' />
                            <div className='ku'>{'>'}</div>
                        </a>
                    </div>

                </div>
                <div className='company fadeInFlag'>
                    <div className='name cssanimation'>
                        株式会社 三慶
                    </div>

                    <div className='body'>
                        <div id='sankey' className='fadeInTopFlag'>
                            <img className='cssanimation' src={sankey_base} alt='' />
                            <img className='cssanimation' src={sankey_copy} alt='' />
                            <img className='cssanimation' src={sankey_kita5} alt='' />
                            <img className='cssanimation' src={sankey_kita5_add} alt='' />
                            <img className='cssanimation' src={sankey_otaru} alt='' />
                            <img className='cssanimation' src={sankey_otaru_add} alt='' />
                        </div>
                    </div>
                    <div className='button'>
                        <a target="_blank" href="http://www.pachinco.co.jp/" rel="noreferrer">
                            <div className='out-frame'></div>
                            <div className='in-frame'></div>
                            <img src={sankey_logo} alt='' />
                            <div className='ku'>{'>'}</div>
                        </a>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Group;
