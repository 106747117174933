import React from "react";
import './style.css';

const TopBarTitle = (props: { title: string }) => {
    return (
        <>
            <div className='top-title fadeInTop'>
                <h1>
                    {props.title}
                </h1>
            </div>
        </>
    )
}

export default TopBarTitle;