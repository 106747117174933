export function initialAnimation() {
    let animateElements = document.getElementsByClassName('fadeInTopFlag');
    let scrollValue = window.pageYOffset;
    let windowHeight = window.innerHeight;

    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('leFadeInTop');
        }
    }
    animateElements = document.getElementsByClassName('fadeInLeftFlag');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('leFadeInLeft');
        }
    }
    animateElements = document.getElementsByClassName('fadeInRightFlag');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('leFadeInRight');
        }
    }
    animateElements = document.getElementsByClassName('fadeInRightFlagContainer');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('fadeInRight');
        }
    }
    animateElements = document.getElementsByClassName('fadeInFlag');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('leFadeIn');
        }
    }
    animateElements = document.getElementsByClassName('fadeInFlagItem');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('fadeIn');
        }
    }
    animateElements = document.getElementsByClassName('fadeInTopFlagItem');
    for (let a = 0; a < animateElements.length; a++) {
        let scrollTop = animateElements[a].getBoundingClientRect().top + scrollValue;
        if (scrollValue + 100 > scrollTop - windowHeight) {
            animateElements[a].classList.add('fadeInTop');
        }
    }
}