import {Helmet} from "react-helmet";
import React from "react";

const NotFound = () => {
    const css =
        '#header{display:none;} ' +
        '    body {\n' +
        '        margin: 8px;\n' +
        '        font-family: "Meiryo";\n' +
        '        display: block;\n' +
        '    }\n' +
        '    h1 {\n' +
        '        display: block;\n' +
        '        font-size: 2em;\n' +
        '        margin-block-start: 0.15em;\n' +
        '        margin-block-end: 0.82em;\n' +
        '        margin-inline-start: 0px;\n' +
        '        margin-inline-end: 0px;\n' +
        '        font-weight: bold;\n' +
        '    }\n' +
        '    p {\n' +
        '        display: block;\n' +
        '        margin-block-start: 1em;\n' +
        '        margin-block-end: 1em;\n' +
        '        margin-inline-start: 0px;\n' +
        '        margin-inline-end: 0px;\n' +
        '    }'

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>404 Not Found</title>
                <meta name='errorpage' content='true'/>
                <meta name='errortype' content='404 - Not Found'/>
                <meta name='prerender-status-code' content='404'/>
                <meta name="robots" content="noindex"/>
                <meta name="googlebot" content="noindex"/>
            </Helmet>
            <style>
                {css}
            </style>
            <h1>Not Found</h1>
            <p>The requested URL was not found on this server.</p>
            <hr/>
        </div>
    )
}

export default NotFound;