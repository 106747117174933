import React from 'react';
import './css/style.css';

import logo_fds from './img/logo_FDS.png'
import logo_umeya from './img/logo_Umeya.png'
import logo_life from './img/logo_LifeBuddy.png'
import logo_sankey from './img/logo_Sankey.png'

import { Helmet } from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import ScrollToTop from "../../components/scrollToTopOnMount";


function Profile() {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <meta charSet="utf-8" />
                <title>会社概要｜FDS</title>
                <meta name="Description"
                    content="FDSホールディングスグループの事業内容や、設立日、資本金、役員、従業員数等といった情報を掲載しております。" />
            </Helmet>
            <TopBarTitle title="会社概要"></TopBarTitle>
            <div id='profile'>
                {/*FDS*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1">
                            <div className='fadeInTopFlag'><img src={logo_fds}
                                className="img-responsive logo cssanimation"
                                alt="FDS Holdings" />
                            </div>
                            <table className="table table-striped cssanimation  ">
                                <tbody>
                                    <tr>
                                        <th className="col-lg-3"><span>商号</span></th>
                                        <td><span>株式会社FDSホールディングス</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>事業内容</span></th>
                                        <td><span>コンサルティング業</span><br />
                                            <span>不動産業</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>設立日</span></th>
                                        <td><span>2016年10月</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>本社所在地</span></th>
                                        <td><span>北海道札幌市中央区北４条西13丁目1番2号</span><br />
                                            <span>RICH植物園BLDG. 3階</span><br />
                                            <span>TEL 011-616-0808</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>取締役</span><br /><span>執行役員</span></th>
                                        <td>
                                            <ul className="list-unstyled">
                                                <li><span>代表取締役会長</span> <span>高木 勲</span></li>
                                                <li><span>代表取締役社長</span> <span>金 大器</span></li>
                                                <li><span>取締役相談役</span> <span>金 恵美</span></li>
                                                <li><span>会計参与</span> <span>林田 敬二</span></li>
                                                <li><span>取締役</span> <span>高木 大蔵</span></li>
                                                <li><span>執行役員</span> <span>平井 肖吾</span></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span>資本金</span></th>
                                        <td><span>600万円</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='container banner'>
                    グループ会社一覧
                </div>
                {/*梅屋*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1">
                            <div className='fadeInTopFlag'><img src={logo_umeya}
                                className="img-responsive logo cssanimation"
                                alt="株式会社　梅屋" />
                            </div>
                            <table className="table table-striped  cssanimation ">
                                <tbody>
                                    <tr>
                                        <th className="col-lg-3"><span>商号</span></th>
                                        <td><span>株式会社 梅屋</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>事業内容</span></th>
                                        <td><span>菓子製造、販売業</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>設立日</span></th>
                                        <td><span>2016年11月</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>本社所在地</span></th>
                                        <td><span>北海道旭川市高砂台2丁目2-11</span><br /><span>TEL 0166-61-7998</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>取締役</span></th>
                                        <td>
                                            <ul className="list-unstyled">
                                                <li><span>代表取締役</span> <span>金 大器</span></li>
                                                <li><span>取締役専務</span> <span>平井 肖吾</span></li>
                                                <li><span>監査役</span> <span>孫 慧莉花</span></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span>資本金</span></th>
                                        <td><span>1000万円</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>WEBサイト</span></th>
                                        <td><a href="http://www.ho-umeya.co.jp/"
                                            target="_blank" rel="noreferrer"><span>http://www.ho-umeya.co.jp/</span></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/*ライフ*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1">
                            <div className='fadeInTopFlag'><img src={logo_life}
                                className="img-responsive logo cssanimation"
                                alt="株式会社らいふばでぃ" /></div>
                            <table className="table table-striped  cssanimation ">
                                <tbody>
                                    <tr>
                                        <th className="col-lg-3"><span>商号</span></th>
                                        <td><span>株式会社 らいふばでぃ</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>事業内容</span></th>
                                        <td><span>介護事業</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>設立日</span></th>
                                        <td><span>2016年11月</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>本社所在地</span></th>
                                        <td><span>北海道札幌市中央区北4条西13丁目 1番地 2</span><br />
                                            <span>TEL 011-616-0808</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>取締役</span></th>
                                        <td>
                                            <ul className="list-unstyled">
                                                <li><span>代表取締役</span> <span>金 大器</span></li>
                                                <li><span>取締役</span> <span>高木 大蔵</span></li>
                                                <li><span>取締役</span> <span>林田 敬二</span></li>
                                                <li><span>監査役</span> <span>孫 慧莉花</span></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span>資本金</span></th>
                                        <td><span>1600万円</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>WEBサイト</span></th>
                                        <td><a href="http://www.life-buddy.co.jp/"
                                            target="_blank" rel="noreferrer"><span>http://www.life-buddy.co.jp/</span></a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>

                {/*三慶*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1">
                            <div className='fadeInTopFlag'><img src={logo_sankey}
                                className="img-responsive logo cssanimation"
                                alt="SANKEY 株式会社 三慶" /></div>
                            <table className="table table-striped  cssanimation ">
                                <tbody>
                                    <tr>
                                        <th className="col-lg-3"><span>商号</span></th>
                                        <td><span>株式会社 三慶</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>事業内容</span></th>
                                        <td><span>アミューズメント事業</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>設立日</span></th>
                                        <td><span>1967年12月</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>本社所在地</span></th>
                                        <td><span>北海道札幌市中央区北5条西16丁目1番1号</span><br />
                                            <span>ビッグスロット北5条店2階</span><br />
                                            <span>TEL 011-616-0806</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>取締役</span></th>
                                        <td>
                                            <ul className="list-unstyled">
                                                <li><span>代表取締役</span> <span>金 大器</span></li>
                                                <li><span>取締役専務</span> <span>高木 大蔵</span></li>
                                                <li><span>監査役</span> <span>孫 慧莉花</span></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span>資本金</span></th>
                                        <td><span>2750万円</span></td>
                                    </tr>
                                    <tr>
                                        <th><span>WEBサイト</span></th>
                                        <td><a href="http://www.pachinco.co.jp/"
                                            target="_blank" rel="noreferrer"><span>http://www.pachinco.co.jp/</span></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Profile;
