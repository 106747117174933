import React from 'react';
import './css/style.css';


function Success() {
    return (
        <>
            <div className="container mt-5 p-lg-5 bg-light mb-5 success-text">
                <h1 className='center'>お問い合わせ完了</h1><br/>
                <h2>お問い合わせありがとうございます。</h2>
                <h2>お問い合わせ内容の確認メールを送信しました。</h2>
                <br/>
                <h2>内容の確認後、担当よりご連絡させていただきます。</h2>
                <br/>
            </div>
        </>
    );
}


export default Success;
