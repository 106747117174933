import React from 'react';
import './css/style.css';
import logo from './img/fds_corporate.png';
import company from './img/web_FDS_Photo_Kaisya.png';
import ceo from './img/web_FDS_Photo_CEO.jpg';
import group from './img/web_FDS_Photo_Group.png';

import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";
import Footer from "../../footer/footer";
import {Link} from "react-router-dom";
import ScrollToTop from "../../components/scrollToTopOnMount";


function Company() {
    return (
        <>

            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>会社案内｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスは、お客様に４つの慶びを創造する企業グループです。"/>
            </Helmet>
            <TopBarTitle title="会社案内"></TopBarTitle>
            <div id='company'>
                <div className='container1'>
                    <div className='text cssanimation fadeIn'>
                        <div><span>私たちは４つの慶びを創造する企業グループ。</span></div>
                        <div>
                            <span>
                                私たちFDSホールディングスグループは、<br className='tab'/>菓子処「梅屋」、<br></br>
                            デイサービスの「らいふばでぃ」、<br className='tab'/>アミューズメント「三慶」を<br className='pc'></br>
                            運営しています。
                            </span>
                        </div>
                        <div>
                            <span>
                                「お客様の慶び、地域の方の慶び、<br className='tab'/>社員の慶び、未来への慶び」<br></br>
                            ４つの慶びを創造する企業グループです。
                            </span>
                        </div>
                        <div>
                            <span>
                                私共は、小売業から製造、アミューズメント、<br className='tab'/>介護、不動産、<br className='pc'></br>
                            IT産業、コンサルティング事業等<br className='tab'/>運営ノウハウを基礎に、<br className='pc'></br>
                            グループの中小企業や<br className='tab'/>サービス業を中心に様々な課題解決を行う<br className='tab'/>企業です。
                            </span>
                        </div>
                    </div>
                    <div className='img fadeInRightFlag'>
                        <img src={logo} className='cssanimation' alt="logo"/>
                    </div>
                </div>
                <div className='container2'>
                    <div className='button cssanimation fadeInRightFlagContainer'>
                        <Link to='/profile' className='button cssanimation'>
                            <div className='imgContainer'>
                                <img src={company} alt=''/>
                            </div>
                            <div className='text'>
                                会社概要
                            </div>
                        </Link>
                    </div>
                    <div className='button cssanimation fadeInRightFlagContainer'>
                        <Link to='/greeting' className='button cssanimation'>
                            <div className='imgContainer'>
                                <img src={ceo} alt='ceo'/>
                            </div>
                            <div className='text'>
                                会長挨拶
                            </div>
                        </Link>
                    </div>
                    <div className='button cssanimation fadeInRightFlagContainer'>
                        <Link to='/group' className='button cssanimation'>
                            <div className='imgContainer'>
                                <img src={group} alt=''/>
                            </div>
                            <div className='text'>
                                グループ会社
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}


export default Company;
