import React, {useCallback, useEffect, useRef} from 'react';
import logo from './logo.png';
import './style.css';
import {Link} from "react-router-dom";

function Footer() {
    const footer = useRef<null | HTMLDivElement>(null);

    function analyticsFooter(link: string) {
        // @ts-ignore
        window.gtag('event', 'Click', {'event_category': 'footer', 'event_label': link, 'value': '0'});
    }

    const footerView = useCallback(() => {
        const bottomPosition = document.body.offsetHeight - (window.scrollY + window.innerHeight);
        if (bottomPosition < 100) {
            footer.current?.classList.add('fadeInBottom');
        }
    }, []);
    useEffect(() => {
        window.addEventListener('scroll', footerView);
        return () => window.removeEventListener('scroll', footerView);
    }, );

    return (
        <div id="footer" ref={footer} className='cssanimation'>
            <div className='container1'>
                <Link to={'/'} onClick={() => analyticsFooter('ロゴ')}>
                    <img src={logo} alt='logo'></img>
                </Link>
                <div className='address'>
                    〒060-0004<br/>
                    北海道札幌市中央区北4条西13丁目1番2号<br/>
                    RICH植物園BLDG.3階<br/>
                    TEL 011-616-0808
                </div>
            </div>
            <div className='container2'>
                <Link to='/business' onClick={() => analyticsFooter('事業内容')}>事業内容<br/></Link>
                <Link to='/business' onClick={() => analyticsFooter('保険不動産事業')}>ー 保険不動産事業 <br/></Link>
                <Link to='/business' onClick={() => analyticsFooter('総合コンサルティング事業')}>ー 総合コンサルティング事業 <br/></Link>
                <Link to='/business' onClick={() => analyticsFooter('ITシステムAPP開発事業')}>ー ITシステムAPP開発事業 <br/></Link>
            </div>
            <div className='container3'>
                <Link to='/company' onClick={() => analyticsFooter('会社案内')}>会社案内<br/></Link>
                <Link to='/profile' onClick={() => analyticsFooter('会社概要')}>ー 会社概要<br/></Link>
                <Link to='/motto' onClick={() => analyticsFooter('社訓')}>ー 企業理念・社訓<br/></Link>
                <Link to='/group' onClick={() => analyticsFooter('グループ会社')}>ー グループ会社<br/></Link>
                <Link to='/greeting' onClick={() => analyticsFooter('会長挨拶')}>ー 会長挨拶</Link>
            </div>
            <div className='container4'>
                <a target="_blank" rel="noreferrer" href='https://fds-hd-recruit.com/' onClick={() => analyticsFooter('採用情報')}>採用情報 <br
                    className='first'></br></a>
                <Link to='/contact' onClick={() => analyticsFooter('お問い合わせ')}>お問い合わせ<br/></Link>
                <Link to='/sitemap' onClick={() => analyticsFooter('サイトマップ')}>サイトマップ<br/></Link>
                <Link to='/privacy' onClick={() => analyticsFooter('プライバシーポリシー')}>プライバシーポリシー</Link>
            </div>
            <div id='atsign'>
                ©2022 Four Delights Holdings Co., Ltd.
            </div>
        </div>
    );
}

export default Footer;
