import React, { useRef,useState, useEffect, useCallback } from 'react';
import './css/style.css';
import Footer from "../../footer/footer";
import {Helmet} from "react-helmet";
import TopBarTitle from "../../components/topBarTitle/topBarTitle";

import arrow_up from './img/arrow_up.png';
import B2B_menu_button from './img/B2B_menu_button.png'
import IT_menu_button from './img/IT_menu_button.png'
import consult_menu_button from './img/consult_menu_button.png'



import cube_b2b from './img/cube_b2b.png'
import cube_it from './img/cube_it.png'
import cube_konsaru from './img/cube_konsaru.png'



import B2B_image1 from './img/B2B_logo.jpg'
import B2B_image2 from './img/B2B_item.png'
import B2B_image3 from './img/B2B_tori.jpg'

import IT_image1 from './img/IT_image1.png'
import IT_image2 from './img/IT_image2.jpg'

import Konsaru_image1 from './img/Konsaru_image1.jpg'




import ScrollToTop from "../../components/scrollToTopOnMount";
import {Link} from "react-router-dom";

import {scroller} from 'react-scroll';
import {useRecoilState} from "recoil";
import {contactContentSelectedInBusiness} from "../../globalState/contactContentSelectedInBusiness";


const Business: React.FC = () => {
    // useRef の部分
    const B2B_button = useRef<HTMLImageElement>(null);
    const B2B_wide = useRef<HTMLDivElement>(null);
    const B2B_contents = useRef<HTMLDivElement>(null);
    const IT_button = useRef<HTMLImageElement>(null);
    const IT_wide = useRef<HTMLDivElement>(null);
    const IT_contents = useRef<HTMLDivElement>(null);
    const konsaru_button = useRef<HTMLImageElement>(null);
    const konsaru_wide = useRef<HTMLDivElement>(null);
    const konsaru_contents = useRef<HTMLDivElement>(null);
    const arrow = useRef<HTMLDivElement>(null);

    // useState の部分
    const [B2B_scroll_open_allow, set_B2B_scroll_open_allow] = useState(false);
    const [IT_scroll_open_allow, set_IT_scroll_open_allow] = useState(false);
    const [konsaru_scroll_open_allow, set_konsaru_scroll_open_allow] = useState(false);
    const [B2B_once_view, set_B2B_once_view] = useState(false);
    const [IT_once_view, set_IT_once_view] = useState(false);
    const [consult_once_view, set_consult_once_view] = useState(false);

    // useCallback の部分
    const returnTop = useCallback(() => {
        scroller.scrollTo("root", {
            smooth: true,
            offset: 0,
            duration: 500,
        });
    }, []);

    const allTrue = useCallback(() => {
        set_B2B_scroll_open_allow(true);
        set_IT_scroll_open_allow(true);
        set_konsaru_scroll_open_allow(true);
    }, []);

    const allClose = useCallback(() => {
        B2B_contents.current?.classList.add("close");
        IT_contents.current?.classList.add("close");
        konsaru_contents.current?.classList.add("close");
    }, []);

    const scroll = useCallback((to: string, duration: number) => {
        scroller.scrollTo(to, {
            smooth: true,
            offset: -200,
            duration: duration,
        });
    }, []);

    

    const B2BSetOnce = useCallback(() => {
        if (!B2B_once_view) {
            // @ts-ignore
            window.gtag('event', 'business_detail', {'event_category': 'business', 'event_label': 'B2B', 'value': '0'});
            set_B2B_once_view(true);
        }
    }, [B2B_once_view]);

    const ITSetOnce = useCallback(() => {
        if (!IT_once_view) {
            // @ts-ignore
            window.gtag('event', 'business_detail', {'event_category': 'business', 'event_label': 'IT', 'value': '0'});
            set_IT_once_view(true);
        }
    }, [IT_once_view]);

    const ConsultSetOnce = useCallback(() => {
        if (!consult_once_view) {
            // @ts-ignore
            window.gtag('event', 'business_detail', {
                'event_category': 'business',
                'event_label': 'Consult',
                'value': '0'
            });
            set_consult_once_view(true);
        }
    }, [consult_once_view]);




    const B2B_button_click = useCallback(() => {
        B2BSetOnce();
        allTrue();
        allClose();
        set_IT_scroll_open_allow(false);
        set_konsaru_scroll_open_allow(false);
        scroll(B2B_contents.current?.id ?? "", 500);
    }, [allTrue, allClose, B2BSetOnce, scroll]);

    const IT_button_click = useCallback(() => {
        ITSetOnce();
        allTrue();
        allClose();
        set_konsaru_scroll_open_allow(false);
        set_B2B_scroll_open_allow(false);
        scroll(IT_contents.current?.id ?? "", 500);
    }, [allTrue, allClose, ITSetOnce, scroll]);

    const konsaru_button_click = useCallback(() => {
        ConsultSetOnce();
        allTrue();
        allClose();
        set_B2B_scroll_open_allow(false);
        set_IT_scroll_open_allow(false);
        scroll(konsaru_contents.current?.id ?? "", 500);
    }, [allTrue, allClose, ConsultSetOnce, scroll]);

    const B2B_wide_click = useCallback(() => {
        B2BSetOnce();
        B2B_contents.current?.classList.toggle("close");
    }, [B2BSetOnce]);

    const IT_wide_click = useCallback(() => {
        ITSetOnce();
        IT_contents.current?.classList.toggle("close");
    }, [ITSetOnce]);

    const konsaru_wide_click = useCallback(() => {
        ConsultSetOnce();
        konsaru_contents.current?.classList.toggle("close");
    }, [ConsultSetOnce]);

    
    // useEffect の部分
    useEffect(() => {
        const handleScroll = () => {
            // スクロールハンドラーのロジックをここに実装
            // 例: const newScrollAmount = window.scrollY;
            // B2B, IT, konsaruの位置を取得し、スクロール位置に応じて処理を行う
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [B2B_scroll_open_allow, IT_scroll_open_allow, konsaru_scroll_open_allow, B2BSetOnce, ITSetOnce, ConsultSetOnce]);

    function analyticsBusiness(link: string) {
        // @ts-ignore
        window.gtag('event', 'Click', {'event_category': 'business', 'event_label': link, 'value': '0'});
    }

    const [,setSelectBusiness] = useRecoilState(contactContentSelectedInBusiness)

    return (
        <>

            <ScrollToTop/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>事業内容｜FDS</title>
                <meta name="Description"
                      content="FDSホールディングスは、損害保険、不動産、アプリ開発/販売、企業ホームページ制作、システム受託開発、総合コンサルティング等といった様々な事業を展開しております。"/>
            </Helmet>
            <TopBarTitle title="事業内容"></TopBarTitle>
                <div id='business' >
                    <div className='container'>
                        <div className='text1 center'>
                            <div className='left'>
                                ホールディングスグループで培った<br className='tab'/> ノウハウを活かし、<br></br>
                                お客様を全力でサポートします。
                            </div>
                        </div>
                        <div className='menu-container container'>
                             
                            <img src={B2B_menu_button} className='col-3 b2b' id="B2B_button" ref={B2B_button} alt='' onClick={() => {
                                B2B_button_click();
                                analyticsBusiness('menu-B2B');
                            }}/>
                            <img src={IT_menu_button} className='col-3 it' id="IT_button" ref={IT_button} alt='' onClick={() => {
                                IT_button_click();
                                analyticsBusiness('menu-IT');
                            }}/>
                            <img src={consult_menu_button} className='col-3 konsaru' id="konsaru_button" alt='' ref={konsaru_button}
                                 onClick={() => {
                                     konsaru_button_click();
                                     analyticsBusiness('menu-consult');
                                 }}/>
                        </div>

                        <div className="contents">
                            <div id="B2B_wide" className="wide" ref={B2B_wide} onClick={B2B_wide_click}>
                                <div className='container'>
                                    <div className='text'>
                                        B2B事業
                                    </div>
                                    <img src={cube_b2b} alt=''/>
                                </div>
                            </div>
                            <div id="B2B_contents" className="body close" ref={B2B_contents}>
                                <div id="B2B_body">
                                    <div className='text1'>
                                        <div className='left'>
                                            変化の激しい社会に備えと挑戦を。<br/>
                                            損害保険から代理店営業まで<br className='tab'/>FDSホールディングスに相談し<br className='pc'/>
                                            て<br className='tab'/>みませんか？
                                        </div>
                                    </div>
                                    <div className='form'>
                                        <Link to='/contact' onClick={() => {analyticsBusiness('form-B2B');setSelectBusiness("損害保険について");}}>
                                            <div className='formButton'>
                                                お問い合わせフォーム<span></span>{'>'}
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='text2'>
                                        ・損害保険
                                    </div>

                                    <div className='logo'>
                                        <img src={B2B_image1} alt=''/>
                                    </div>
                                    <div className='items'>
                                        <img src={B2B_image2} alt=''/>
                                    </div>
                                    <div className='tori'>
                                        <img src={B2B_image3} alt=''/>
                                    </div>
                                    <div className='text3 center'>
                                        <div className='left'>
                                            大切な家族の為に、守るべき会社の為に安心を備えませんか？<br/>
                                            お気軽に相談ください。
                                        </div>
                                    </div>
                                    <div className='text4'>
                                        <p>・不動産</p>
                                        <p>・代理店営業（予定）</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="contents">
                            <div id="IT_wide" className="wide" ref={IT_wide} onClick={IT_wide_click}>
                                <div className='container'>
                                    <div className='text'>
                                        ITシステムAPP開発事業
                                    </div>
                                    <img src={cube_it} alt=''/>
                                </div>
                            </div>
                            <div id="IT_contents" className="body close" ref={IT_contents}>
                                <div id="IT_body">
                                    <div className='text1'>
                                        <div className='left'>
                                            ホームページからオリジナルアプリ開発<br className='tab'/> まで。<br/>
                                            お店や会社をデジタルでサポートします。<br/>
                                            お気軽にご相談下さい。
                                        </div>
                                    </div>
                                    <div className='form'>
                                        <Link to='/contact' onClick={() => {analyticsBusiness('form-IT');setSelectBusiness("ITシステムAPP開発について")}}>
                                            <div className='formButton'>
                                                お問い合わせフォーム<span></span>{'>'}
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='text2'>
                                        小売業用会員サービスアプリ開発<br/>
                                        ・予約システム<br/>
                                        ・決済システム<br/>
                                        ・社内アプリシステム<br/>
                                        ・来店ポイントシステム<br/>
                                        ・クーポンシステム
                                    </div>
                                    <div className='image1'>
                                        <img src={IT_image1} alt=''/>
                                    </div>
                                    <div className='text3'>
                                        ・HP製作、デザイン<br/>
                                        ・企業カタログ製作<br/>
                                        ・広告デザイン<br/>
                                        ・キャラクター製作
                                    </div>
                                    <div className='image2'>
                                        <div className='text'>HP制作例</div>
                                        <img src={IT_image2} alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="contents">
                            <div id="konsaru_wide" className="wide" ref={konsaru_wide} onClick={konsaru_wide_click}>
                                <div className='container'>
                                    <div className='text'>
                                        総合コンサルティング事業
                                    </div>
                                    <img src={cube_konsaru} alt=''/>
                                </div>
                            </div>
                            <div id="konsaru_contents" className="body close" ref={konsaru_contents}>
                                <div id="konsaru_body">
                                    <div className='text1 center'>
                                        <div className='left'>
                                            中小企業やサービス業の人材開発から<br className='tab'/> 働き方改革等、労務<br className='pc'/>
                                            の課題解決やDX、<br className='tab'/>ビジネス用システムやAPP等幅広く<br className='tab'/>様々<br
                                            className='pc'/>
                                            な課題を解決することができます。
                                        </div>
                                    </div>
                                    <div className='text2'>
                                        ・経営コンサルティング<br/>
                                        ・人材育成コンサルティング<br/>
                                        ・人材採用コンサルティング<br/>
                                        ・IT・DXコンサルティング
                                    </div>
                                    <div className='image1'>
                                        <img src={Konsaru_image1} alt=''/>
                                    </div>
                                    <div className='form'>
                                        <Link to='/contact' onClick={() => {analyticsBusiness('form-consult');setSelectBusiness("コンサルティングについて");}}>
                                            <div className='formButton'>
                                                お問い合わせフォーム<span></span>{'>'}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="top_arrow" ref={arrow}>
                        <div onClick={returnTop}>
                            <img src={arrow_up} alt="矢印ボタン"/>
                        </div>
                    </div>
                </div>
            <Footer></Footer>
        </>

    );
}


export default Business;
