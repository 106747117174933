import React from 'react';
import './css/style.css';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import more from './img/more.png'

import buttonText1 from './img/web_FDS_Button_Jigyo01.png'
import buttonText2 from './img/web_FDS_Button_Group01.png'

import copy1 from './img/web_TopCopy_01.png'
import copy2 from './img/web_TopCopy_02.png'
import copy3 from './img/web_TopCopy_03.png'
import copy4 from './img/web_TopCopy_04.png'
import copy5 from './img/web_TopCopy_05.png'
import copy6 from './img/web_TopCopy_06.png'
import copy7 from './img/web_TopCopy_07.png'
import copy8 from './img/web_TopCopy_08.png'
import copy9 from './img/web_TopCopy_09.png'
import copy10 from './img/web_TopCopy_10.png'

function Top() {
    function analyticsTop(link: string) {
        // @ts-ignore
        window.gtag('event', 'Click', {'event_category': 'top', 'event_label': link, 'value': '0'});
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>FDS</title>
                <meta name="Description"
                      content="FDSホールディングスは、小売業,製造,アミューズメント,介護,不動産,IT産業,コンサルティング事業等の運営ノウハウを基礎に、グループの中小企業やサービス業を中心に様々な課題解決を行う企業です。"/>
            </Helmet>
            <div id='top'>
                <h2 className='img1 fadeIn'>
                    <div className='text leScaleYIn'>
                        <img src={copy1} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字1'/>
                        <img src={copy2} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字2'/>
                        <img src={copy3} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字3'/>
                        <img src={copy4} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字4'/>
                        <img src={copy5} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字5'/>
                        <img src={copy6} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字6'/>
                        <img src={copy7} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字7'/>
                        <img src={copy8} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字8'/>
                        <img src={copy9} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字9'/>
                        <img src={copy10} alt='株式会社FDSホールディングスのキャッチ、「中小企業と未来を歩く」の分割文字10'/>
                    </div>
                </h2>
                <h2 className='flex'>
                    <Link to='/business' onClick={() => analyticsTop('事業内容')}>
                        <div className='img2 cssanimation zoomInTop' id='flex1'></div>
                        <div className='more1'>
                            <img src={more} alt='FDSホールディングス｜事業内容へ進むボタン装飾'/>
                        </div>
                        <div className='businessText cssanimation fadeIn'>
                            <img src={buttonText1} alt='FDSホールディングス｜事業内容'/>
                        </div>
                    </Link>
                    <Link to='/group' onClick={() => analyticsTop('グループ会社について')}>
                        <div className='img3 cssanimation zoomInLeft' id='flex2'></div>
                        <div className='more2'>
                            <img src={more} alt='FDSホールディングス｜グループ会社へ進むボタン装飾'/>
                        </div>
                        <div className='groupText cssanimation fadeIn'>
                            <img src={buttonText2} alt='FDSホールディングス｜グループ会社'/>
                        </div>
                    </Link>
                </h2>
            </div>
        </>
    );
}


export default Top;
