import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {initialAnimation} from "./initialAnimation";

export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
        initialAnimation();

    }, [pathname]);

    return null;
}
